<template>
  <v-container>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-btn
          color="primary"
          @click="$router.push({name: 'admin:new_candidate'})"
        >
          <v-icon left>
            add
          </v-icon>
          Nuevo Candidato
        </v-btn>
        <v-btn
          @click="showfilters = true"
        >
          <v-icon left>
            mdi-filter
          </v-icon>
          Filtros
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="candidates"
          :pagination.sync="pagination"
          hide-actions
          class="elevation-3"
        >
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                align="left"
                style="font-size: small; font-weight: bold"
              >
                {{ header.text }}
              </th>
              <th>Acciones</th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.first_name }} {{ props.item.last_name }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.national_id }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.last_company }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.last_position }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.city_name }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.city_region.country }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              {{ props.item.email }}
            </td>
            <td :style="{backgroundColor: props.item.candidate_color}">
              <v-btn
                :to="{name: 'admin:candidate:applications', params: {uuid: props.item.uuid }}"
              >
                <v-icon left>
                  person
                </v-icon>
                Detalle
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="candidates.length > 0"
      justify-center
      mt-3
    >
      <v-flex xs4>
        <v-card>
          <v-card-text>
            <div class="text-xs-center">
              <v-pagination
                v-model="page"
                :length="pages"
                total-visible="5"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      v-model="showfilters"
      right
      temporary
      fixed
    >
      <v-card
        v-if="showfilters"
        flat
      >
        <v-card-title class="headline">
          Filtros
        </v-card-title>
        <v-card-text class="justify-center">
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="filters.firstname"
                label="Nombre"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.lastname"
                label="Apellido"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.national_id"
                label="RUT/DNI/NIT"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.last_company"
                label="Empresa"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.last_position"
                label="Cargo"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.email"
                label="Email"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="filters.city"
                label="Ciudad o Comuna"
                prepend-icon="search"
              />
            </v-flex>
            <v-flex xs12>
              <CountryPicker
                v-model="filters.country"
                prepend-icon="search"
                label="País de Residencia"
                :default-to-chile="false"
              />
            </v-flex>
            <v-flex xs12>
              <v-select
                v-model="filters.cand_status"
                :items="statesSelect"
                prepend-icon="search"
                chips
                multiple
                outlined
                label="Estado del Candidato"
              />
            </v-flex>
            <v-flex xs12>
              <v-combobox
                v-model="filters.tags"
                multiple
                prepend-icon="search"
                label="Tags"
                append-icon
                chips
                deletable-chips
                hint="Presiona Enter después de agregar cada tag"
                persistent-hint
              />
            </v-flex>
            <v-layout
              xs12
              d-flex
              justify-left
            >
              <v-flex
                xs3
                mt-1
              >
                <v-btn
                  color="primary"
                  type="submit"
                  @click="applyfilters"
                >
                  Filtrar
                </v-btn>
                <v-btn @click="clearAndFilter">
                  Limpiar
                </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import axios from 'axios';
import CountryPicker from '@/components/inputs/CountryPicker.vue';
import OHCStateMixin from '@/components/mixins/OHCStateMixin.vue';
import JWTConfigMixin from '../../components/mixins/JWTConfigMixin.vue';

export default {
  name: 'CandidatesList',
  components: {
    CountryPicker,
  },
  mixins: [JWTConfigMixin, OHCStateMixin],
  data() {
    return {
      showfilters: false,
      candidates: [],
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
      filters: {
        firstname: '',
        lastname: '',
        national_id: '',
        last_company: '',
        last_position: '',
        email: '',
        city: '',
        country: '',
        cand_status: '',
        tags: '',
      },
      page: 1,
      pages: 1,
      nextPage: '',
      prevPage: '',
      headers: [
        {
          text: 'Nombre',
          value: 'name',
        },
        {
          text: 'RUT/DNI/NIT',
          value: 'national_id',
        },
        {
          text: 'Empresa',
          value: 'last_company',
        },
        {
          text: 'Cargo',
          value: 'last_position',
        },
        {
          text: 'Ciudad',
          value: 'city',
        },
        {
          text: 'País de Residencia',
          value: 'nationality',
        },
        {
          text: 'Email',
          value: 'email',
        },
      ],
    };
  },
  computed: {
    query_params() {
      let parsed = 0;
      let qstr = '';
      Object.keys(this.filters).forEach(val => {
        if (this.filters[val]) {
          if (parsed > 0) {
            qstr = `${qstr}&`;
          } else {
            qstr = '?';
          }
          qstr = `${qstr}${val}=${this.filters[val]}`;
          parsed += 1;
        }
      });
      return qstr;
    },
  },
  watch: {
    page(newVal, oldVal) {
      let pageEndpoint = '';
      if (Math.abs(newVal - oldVal) > 1) {
        const queryConstruct = `${this.query_params !== '' ? `${this.query_params}&` : '?'}page=${newVal}`;
        pageEndpoint = `${this.$store.state.endpoints.candidates.list}${queryConstruct}`;
      } else if (newVal > oldVal) {
        pageEndpoint = this.nextPage;
      } else {
        pageEndpoint = this.prevPage;
      }
      this.turnPage(pageEndpoint);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadCandidates();
    });
  },
  methods: {
    clearAndFilter() {
      Object.keys(this.filters).forEach(val => {
        this.filters[val] = '';
      });
      this.applyfilters();
    },
    applyfilters() {
      this.$store.commit('setLoading');
      axios.get(`${this.$store.state.endpoints.candidates.list}${this.query_params}`, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.candidates = response.data.results;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.$store.commit('unsetLoading');
          this.page = 1;
          this.showfilters = false;
          this.setPaginator();
        });
    },
    turnPage(pageLink) {
      if (!pageLink) {
        return;
      }
      this.$store.commit('setLoading');
      axios.get(pageLink, this.jwtConfig)
        .then(response => {
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.candidates = response.data.results;
          this.$store.commit('unsetLoading');
          this.setPaginator();
        });
    },
    setPaginator() {
      this.pagination.page = this.page;
      this.pagination.rowsPerPage = this.candidates.length;
    },
    loadCandidates() {
      axios.get(this.$store.state.endpoints.candidates.list, this.jwtConfig)
        .then(response => {
          this.candidates = response.data.results;
          this.nextPage = response.data.next;
          this.prevPage = response.data.previous;
          this.pages = Math.ceil(response.data.count / response.data.results.length);
          this.setPaginator();
        });
    },
  },
};
</script>
